import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

// Soft UI Dashboard React Base Styles
import colors from "assets/theme/base/colors";

// CONSTANTS
import { STATUS } from "constants/fetchStatus";

import icon from "assets/logos/favicon.png";
import zion_logo from "assets/images/logos/zion_logo.avif";
import AccordionVideo from "./Accordion";
import clientConfig from "utils/clientConfig";

const { gradients } = colors;

export default function PlayList({
  videos,
  showPlayList,
  setShowPlayList,
  setCurrentVideo,
  toggleVideoPlaying,
}) {
  const { data, status } = videos;
  const [videosBySection, setVideosBySection] = useState({})

  const handlePlay = () => {
    const index = 0;
    const { url } = data[index];
    setShowPlayList(!showPlayList);
    setCurrentVideo(url);
  };

  useEffect(() => {
    data && setVideosBySection(data.reduce((acc, video) => {
      if (!acc[video.section]) {
        acc[video.section] = [];
      }
      acc[video.section].push(video);
      return acc;
    }, {}));
  }, [data, setVideosBySection])


  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox
        height="100%"
        bgColor="info"
        variant="gradient"
        sx={{ position: "relative" }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <SoftBox
          width="100%"
          display="flex"
          justifyContent="end"
          alignItems="center"
          sx={{
            position: "absolute",
            top: 0,
            px: "0.5vw",
            py: "1vh",
          }}
        >
          <Icon
            fontSize="large"
            sx={{
              color: colors.white.main,
              cursor: "pointer",
              zIndex: 999999,
              "&:hover": {
                transform: "scale(1.2)",
              },
            }}
            onClick={toggleVideoPlaying}
          >
            close
          </Icon>
        </SoftBox>
        <SoftBox p="1vw" display="flex" justifyContent="flex-start" width="100%">
          <SoftBox
            p={3}
            height="100px"
            width="100px"
            bgColor="dark"
            variant="gradient"
            borderRadius="10px"
            mr="1vw"
            display="flex"
            justifyContent="flex-center"
          >
            <img src={clientConfig.name === "zion" ? zion_logo : icon} alt={`${clientConfig.emailName} icon`} width="100vw" />
          </SoftBox>
          <SoftBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <SoftTypography fontWeight="bold" color="white">
              Deep Focus
            </SoftTypography>
            <SoftTypography variant="overline" color="white">
              Crypto
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox px="1vw" display="flex" justifyContent="space-between" width="100%">
          <SoftBox>
            <SoftBadge badgeContent={`${clientConfig.emailName} play list`} size="xs" />
          </SoftBox>
          <SoftBox display="flex" alignItems="center" gap={3}>
            <Icon
              fontSize="large"
              sx={{
                color: colors.white.main,
                cursor: "pointer",
                "&:hover": {
                  transform: "scale(1.02)",
                },
              }}
              onClick={handlePlay}
            >
              play_circle
            </Icon>
          </SoftBox>
        </SoftBox>
        <SoftBox
          height="100%"
          px="1vw"
          py="1vw"
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            overflow: "auto",
            "& ul": { padding: 0 },
            "&::-webkit-scrollbar": {
              width: "0.2em",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: gradients.info.main,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: colors.white.main,
            },
          }}
        >
          <Divider light sx={{ mt: -2 }} />
          {status === STATUS.ERROR && (
            <SoftTypography
              sx={{ display: "inline" }}
              component="span"
              variant="button"
              color="white"
            >
              Oops, something went wrong!
            </SoftTypography>
          )}
          {
            videosBySection && Object.keys(videosBySection).map((section, index) => (
              <AccordionVideo
                key={index}
                setShowPlayList={setShowPlayList}
                showPlayList={showPlayList}
                setCurrentVideo={setCurrentVideo}
                section={section}
                videos={videosBySection[section]}
                index={index}
              />
            ))
          }
        </SoftBox>
      </SoftBox>
    </Card>
  );
}
